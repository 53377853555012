import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

import './lib/foundation-explicit-pieces'

$(document).foundation();

const poetry = document.getElementById("poetry");
const list = document.getElementById("poetry-list");
const content = document.getElementById('pageContent');


const hide = () => (list.style.display !== 'none') ? poetry.click() : false;
document.querySelectorAll('#poetry-list a').forEach(e => e.addEventListener('click', () => hide()));
poetry.addEventListener('click', e => hide());
content.addEventListener('click', e => hide());





